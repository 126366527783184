<mat-toolbar class="restricted-container">
    <mat-toolbar-row>
        <h1 class="masthead-brand">vilbara code GmbH</h1>
        <nav>
            <a
                mat-button
                class="nav-link"
                routerLinkActive="active"
                [routerLink]="['']"
            >
                Home
            </a>
            <a
                mat-button
                class="nav-link"
                routerLinkActive="active"
                [routerLink]="['impressum']"
            >
                Impressum
            </a>
        </nav>
    </mat-toolbar-row>
</mat-toolbar>

<main class="restricted-container">
    <div>
        <router-outlet></router-outlet>
    </div>

    <footer>
        <p>Copyright &copy; 2020 vilbara code GmbH</p>
    </footer>
</main>
