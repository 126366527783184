<h2>Impressum</h2>

<div>
    <p>vilbara code GmbH</p>
    <p>Windhalmweg 21</p>
    <p>70599 Stuttgart</p>
    <p>Deutschland / Germany</p>
</div>

<div>
    <p>Geschäftsführer: Vilmantas Baranauskas</p>
    <p>Sitz der Gesellschaft: Stuttgart</p>
    <p>Amtsgericht Stuttgart: HRB 770179</p>
    <p>USt-IdNr: DE326128228</p>
</div>

<div>
    <p>Kontakt:</p>
    <p>Telefon: +49 172 8333618</p>
    <p>E-Mail: vilmantas.baranauskas /at/ vilbara-code.de</p>
</div>
