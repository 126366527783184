import { Component } from '@angular/core';

@Component({
    selector: 'vilbara-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    title = 'vilbara-code';
}
