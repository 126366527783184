<h2>Vilmantas Baranauskas: Full Stack Developer & Software Architect</h2>

<p>
    Available for projects within 1-hour drive from
    <a
        href="https://www.google.de/maps/place/Plieningen,+Stuttgart/@48.7240367,8.9757186,10.25z/data=!4m5!3m4!1s0x4799c2fdd3b3e73d:0xcd405275c2ced9ea!8m2!3d48.7087513!4d9.2052496"
        >Stuttgart-Plieningen, Germany</a
    >.
</p>

<h3>Frontend</h3>
<p>
    Angular v2 - v10, TypeScript.
</p>

<h3>Backend</h3>
<p>
    spring, spring-boot, java, kotlin.
</p>

<h3>Identity and Access Management (IAM)</h3>
<p>
    Extensive experience in the area of Identity and Access Management:
    Authentication / login, authorization, registration & password processes.
</p>
<p>
    Design and implementation of user registration & password processes, data
    propagation, authentication and authorization, solutions based on OAuth2 /
    OpenID Connect (both as an identity provider and relying party), licensing
    systems.
</p>
