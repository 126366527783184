import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ImpressumComponent } from './impressum/impressum.component';
import { HomeComponent } from './home/home.component';
import { MatButtonModule } from '@angular/material/button';

const routes: Routes = [
    { path: 'impressum', component: ImpressumComponent },
    { path: '', component: HomeComponent },
    { path: '**', redirectTo: '/' },
];

@NgModule({
    declarations: [AppComponent, HomeComponent, ImpressumComponent],
    imports: [
        BrowserModule,
        MatButtonModule,
        MatToolbarModule,
        RouterModule.forRoot(routes, {
            initialNavigation: 'enabledBlocking',
            relativeLinkResolution: 'legacy',
        }),
    ],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
